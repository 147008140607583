import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../shared/lib/api';
import { IVeileder } from '../shared/lib/types';
import Spinner from '../spinner/Spinner';
import FeilmeldingSide from '../feilside/FeilmeldingSide';

type TParams = {
    veilederId?: string;
    kundeId: string;
};

const VeilederStart = () => {
    const { veilederId, kundeId } = useParams<TParams>();
    const [veileder, setVeileder] = useState<IVeileder>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [egetforetak, setEgetforetak] = useState<boolean>(false);

    useEffect(() => {
        (() => {
            Promise.all([
                api.getVeileder(veilederId, '').then((veileder) => {
                    setVeileder(veileder);
                }),
                api.getInnloggetBruker().then(async (res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetforetak(true);
                    } else {
                        if (kundeId) {
                            const kunde = await api.getKunde(kundeId);
                            setKundeNavn(kunde.navn);
                        }
                    }
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Mal oppsummering';
        })();
    }, [veilederId, kundeId]);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/veiledere" />;
    }

    return (
        <>
            {veileder && (
                <div className="veileder">
                    <div className="page-header page-header--dark page-header--sm">
                        <div className="container">
                            <h1>{veileder.name}</h1>
                        </div>
                    </div>
                    {kundeId && (
                        <div className="page-header">
                            <div className="container">
                                <div className="col-md-6 d-flex align-items-center">
                                    <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                                    <h2>{kundeNavn}</h2>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="page">
                        <div className="container veileder-oppsummering">
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-xl-8">
                                    <div className="section section--white">
                                        <h2 className="upper">{veileder.name}</h2>
                                        <p className="lead" dangerouslySetInnerHTML={{ __html: veileder.intro }} />
                                        <div className="upper">Mal</div>
                                        <div className="row veileder-oppsummering-dokuments">
                                            <div className="col-md-6 d-md-flex align-items-center gap-10 veileder-oppsummering-label">
                                                <span className="icon icon-kundenavn-stor" />
                                                <span className="veilder-oppsummering__text">
                                                    {' '}
                                                    <a href={veileder.downloadLink} download={true}>
                                                        {veileder.name}
                                                    </a>
                                                </span>
                                            </div>
                                            <div className="col-md-6 veileder-oppsummering__actions">
                                                <a href={veileder.downloadLink} download={true} className="btn btn--primary">
                                                    Last ned
                                                </a>
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: veileder.mainbody }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default VeilederStart;
